import React from 'react'
import ArrowLink from '../../ArrowLink/ArrowLink'

const SecurityAdviceContent: React.FC = () =>

  <section className="pt-5 mb-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-8 offset-lg-2">
                  <div className="markdown-content">
                    <h1>Antes de comprar</h1>

                    <h2>1. No uses redes WiFi públicas</h2>

                    <p>Antes de comprar debes tener en cuenta de nunca utilizar redes WiFi públicos para una compra online, nunca es recomendable que la red que estés usando sea la de un restaurant, una estación o una cafetería, ya que correrás muchos riesgos si lo haces.
                    </p>
                    <p>Si puedes esperar, espera para llegar a casa, si no puedes y necesitas hacer una compra, utiliza una VPN que mejore la seguridad del dispositivo.
                    </p>
                    <p>Las redes WiFi-públicas tienen riesgos como ataques de  falsos puntos de acceso, ataques de “hombre en el medio” que hará que un ciberdelincuente se coloque entre tu dispositivo y el sitio que visitas pudiendo así tener todos tus datos personales o financieros.</p>

                    <h2>2. Asegúrate que se trata de una conexión segura</h2>

                    <p>Más allá de asegurarte que se trata de una tienda online segura, es fundamental que te fijes si tiene una conexión segura a la hora de dar tus datos financieros, fíjate que en la barra del navegador aparezca HTTPS y no HTTP.</p>
                    <p>También fíjate que junto a la URL hay un icono de un candado, que demostrará que esa tienda online tiene certificado de seguridad y que puedes hacer una compra de manera segura, si no lo tiene, desconfía y busca más información para asegurarte.</p>

                    <h2>3. Habilitar notificaciones por SMS</h2>

                    <p>La mayoría de las entidades financieras emisoras permiten al usuario registrar un número de teléfono celular para recibir un SMS por las compras online realizadas con la tarjeta, de esta manera, si el usuario no reconoce una compra, puede comunicarse inmediatamente con el servicio de atención de su entidad financiera para no reconocer la compra.</p>

                    <h1>Durante la compra</h1>

                    <h2>4. Crea una cuenta de forma segura</h2>

                    <p>Una vez que te hayas decidido a comprar, la mayoría de las tiendas te obligarán a crear una cuenta en la web a través de la cual podrás hacer un seguimiento del envío o de todos los pedidos que hagas.</p>
                    <p>Al crear una cuenta, hazlo de forma segura, normalmente te pedirán tus datos personales (nombre, dirección, teléfono) y un correo electrónico y una contraseña. No uses la misma contraseña que en otros servicios como tu email o tus redes sociales. Utiliza contraseñas seguras con una longitud mínima de ocho caracteres que contenga letras, número y caracteres especiales.</p>

                    <h2>5. Fíjate qué instalas</h2>

                    <p>Si para una compra online utilizas una aplicación, asegúrate que es fiable, lo primero y fundamental es que la descargues de una tienda de aplicaciones oficiales como puede ser Google Play o App Store.</p>
                    <p>Pero el filtro de estas tiendas no es infalible, así que revisa los permisos de la aplicación al instalarse, busca información en Internet, consulta los comentarios de la tienda de aplicaciones o busca cuántas descargas tiene o cuál es la valoración.</p>

                    <h1>Después de la compra</h1>

                    <h2>6. No guardes el método de pago</h2>

                    <p>La mayor parte de navegadores te preguntarán si quieres guardar el método de pago para próximas compras o incluso si quieres guardarlo en la propia tienda online, no lo hagas.</p>
                    <p>Puede que te parezca incómodo tener que escribir todos los números de tu tarjeta de crédito en cada compra pero será más seguro que dejarla almacenada.</p>
                    <p>En términos generales es mejor que no guardes tus datos y que los escribas en cada compra ya que así evitarás que alguien pueda robar tu cuenta y pagar por ti.</p>

                    <h2>7. Reenvío o reembolso</h2>

                    <p>Asegúrate, por ejemplo, que pasaría si un producto llega roto o defectuoso y quién se encargará de reemplazarlo. No siempre las compras que hacemos online llegan correctamente a casa, en su traslado pueden sufrir golpes o puede venir estropeados, en ese caso, comprueba que derechos tienes para que te reenvíen un nuevo producto o te devuelvan el dinero.</p>

                    <hr/>

                    <ArrowLink to="https://drive.google.com/drive/folders/1LRaTF3QxDbokPDO5riyCZJuQxB47xuhr?usp=sharing" external={true} openInNewTab={true} >Revisa nuestros boletines de seguridad</ArrowLink>
                  </div>
              </div>
            </div>
          </div>
        </section>

  export default SecurityAdviceContent
