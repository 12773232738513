import React from 'react';

import './styles.scss';

const SecurityAdviceHero: React.FC = ({children}) => (
  <section className="security-advice-hero">
  <div className="container">
    <div className="row">
      <div className="col-12 col-lg-8 offset-lg-2">
        <h1>
          Consejos de seguridad
        </h1>
        <p className="lead">
          Sigue las siguientes indicaciones al momento de que realizes tus compras para una experiencia segura.
        </p>
      </div>
    </div>
  </div>
</section>
)

export default SecurityAdviceHero;
