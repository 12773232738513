import React from 'react';

import IndexLayout from '../layouts';

import Footer from '../components/Footer/Footer';
import SEO from '../components/SEO/SEO';

import { LocaleTypes } from '../data/locales';
import SecurityAdviceContent from '../components/SecurityAdvice/SecurityAdviceContent/SecurityAdviceContent';
import SecurityAdviceHero from '../components/SecurityAdvice/SecurityAdviceHero/SecurityAdviceHero';


interface LegalPageProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const LegalPage: React.FC<LegalPageProps> = ({ pathContext, location }) => {

  const content = () => (
    <>
      <SEO title="Consejos de seguridad" />

      <SecurityAdviceHero/>
      <SecurityAdviceContent />
      <Footer theme="gray" />
    </>
  );

  return <IndexLayout render={ content } locale={pathContext.localeCode} location={location} />
}

export default LegalPage;
